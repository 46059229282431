import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _949573c8 = () => interopDefault(import('../client/pages/_lang/aboutus/index.vue' /* webpackChunkName: "" */))
const _6357a2de = () => interopDefault(import('../client/pages/_lang/account/index.vue' /* webpackChunkName: "" */))
const _05d4187e = () => interopDefault(import('../client/pages/_lang/affiliate/index.vue' /* webpackChunkName: "" */))
const _0157075c = () => interopDefault(import('../client/pages/_lang/becomepartner/index.vue' /* webpackChunkName: "" */))
const _7850007e = () => interopDefault(import('../client/pages/_lang/benefits/index.vue' /* webpackChunkName: "" */))
const _9edb0cb8 = () => interopDefault(import('../client/pages/_lang/bftmanagement/index.vue' /* webpackChunkName: "" */))
const _77d23648 = () => interopDefault(import('../client/pages/_lang/buycoin/index.vue' /* webpackChunkName: "" */))
const _78b9335e = () => interopDefault(import('../client/pages/_lang/challenging/index.vue' /* webpackChunkName: "" */))
const _258f9fcc = () => interopDefault(import('../client/pages/_lang/community/index.vue' /* webpackChunkName: "" */))
const _b9affe20 = () => interopDefault(import('../client/pages/_lang/competition/index.vue' /* webpackChunkName: "" */))
const _d6675840 = () => interopDefault(import('../client/pages/_lang/compliance/index.vue' /* webpackChunkName: "" */))
const _5eff5b4c = () => interopDefault(import('../client/pages/_lang/contactUs.vue' /* webpackChunkName: "" */))
const _d46205c4 = () => interopDefault(import('../client/pages/_lang/documentary/index.vue' /* webpackChunkName: "" */))
const _21251a8d = () => interopDefault(import('../client/pages/_lang/download/index.vue' /* webpackChunkName: "" */))
const _aa978fa4 = () => interopDefault(import('../client/pages/_lang/expressly/index.vue' /* webpackChunkName: "" */))
const _e896a0f2 = () => interopDefault(import('../client/pages/_lang/faq/index.vue' /* webpackChunkName: "" */))
const _68a2654a = () => interopDefault(import('../client/pages/_lang/feedback/index.vue' /* webpackChunkName: "" */))
const _2d4b94ba = () => interopDefault(import('../client/pages/_lang/financial/index.vue' /* webpackChunkName: "" */))
const _6d34a79c = () => interopDefault(import('../client/pages/_lang/forget/index.vue' /* webpackChunkName: "" */))
const _1c4714f8 = () => interopDefault(import('../client/pages/_lang/global-community/index.vue' /* webpackChunkName: "" */))
const _6c29f10e = () => interopDefault(import('../client/pages/_lang/global-strategy/index.vue' /* webpackChunkName: "" */))
const _59622853 = () => interopDefault(import('../client/pages/_lang/globalpartners/index.vue' /* webpackChunkName: "" */))

const _12161ef9 = () => interopDefault(import('../client/pages/_lang/hire/index.vue' /* webpackChunkName: "" */))
const _1dddfed4 = () => interopDefault(import('../client/pages/_lang/how-to-buy/index.vue' /* webpackChunkName: "" */))
const _14e5c0de = () => interopDefault(import('../client/pages/_lang/individual/index.vue' /* webpackChunkName: "" */))
const _0fdd4924 = () => interopDefault(import('../client/pages/_lang/job/index.vue' /* webpackChunkName: "" */))
const _4e811d14 = () => interopDefault(import('../client/pages/_lang/launchpool/index.vue' /* webpackChunkName: "" */))
const _45141d2f = () => interopDefault(import('../client/pages/_lang/layout/index.vue' /* webpackChunkName: "" */))
const _33a5aacc = () => interopDefault(import('../client/pages/_lang/login/index.vue' /* webpackChunkName: "" */))
const _216a1f2f = () => interopDefault(import('../client/pages/_lang/lunc-burn/index.vue' /* webpackChunkName: "" */))
const _7818a47e = () => interopDefault(import('../client/pages/_lang/Media-kit/index.vue' /* webpackChunkName: "" */))
const _5b2bc11a = () => interopDefault(import('../client/pages/_lang/modifyverification/index.vue' /* webpackChunkName: "" */))
const _4c17d1df = () => interopDefault(import('../client/pages/_lang/news-report/index.vue' /* webpackChunkName: "" */))
const _a4271146 = () => interopDefault(import('../client/pages/_lang/notice/index.vue' /* webpackChunkName: "" */))
const _e9eaa824 = () => interopDefault(import('../client/pages/_lang/official-verification/index.vue' /* webpackChunkName: "" */))
const _53773786 = () => interopDefault(import('../client/pages/_lang/pagestatus/index.vue' /* webpackChunkName: "" */))
const _1caa4758 = () => interopDefault(import('../client/pages/_lang/promotion/index.vue' /* webpackChunkName: "" */))
const _130123d5 = () => interopDefault(import('../client/pages/_lang/proof-of-reserves/index.vue' /* webpackChunkName: "" */))
const _eee279f6 = () => interopDefault(import('../client/pages/_lang/protectfund/index.vue' /* webpackChunkName: "" */))
const _537f0e7d = () => interopDefault(import('../client/pages/_lang/publish.vue' /* webpackChunkName: "" */))
const _42aa480b = () => interopDefault(import('../client/pages/_lang/quotelist/index.vue' /* webpackChunkName: "" */))
const _ffe9d436 = () => interopDefault(import('../client/pages/_lang/rate/index.vue' /* webpackChunkName: "" */))
const _3f2deac8 = () => interopDefault(import('../client/pages/_lang/welfare/register.vue' /* webpackChunkName: "" */))
const _4ebe28f9 = () => interopDefault(import('../client/pages/_lang/school/index.vue' /* webpackChunkName: "" */))
const _51ec3b66 = () => interopDefault(import('../client/pages/_lang/sitemap/index.vue' /* webpackChunkName: "" */))
const _59517e15 = () => interopDefault(import('../client/pages/_lang/socialCreateAccount/index.vue' /* webpackChunkName: "" */))
const _6e780981 = () => interopDefault(import('../client/pages/_lang/spokesperson/index.vue' /* webpackChunkName: "" */))
const _54fdce87 = () => interopDefault(import('../client/pages/_lang/stop/index.vue' /* webpackChunkName: "" */))
const _357d1400 = () => interopDefault(import('../client/pages/_lang/support/index.vue' /* webpackChunkName: "" */))
const _74b948c2 = () => interopDefault(import('../client/pages/_lang/tokens/index.vue' /* webpackChunkName: "" */))
const _63e122fe = () => interopDefault(import('../client/pages/_lang/topTraderCompetition/index.vue' /* webpackChunkName: "" */))
const _5522b50b = () => interopDefault(import('../client/pages/_lang/useragent/index.vue' /* webpackChunkName: "" */))
const _1022b27d = () => interopDefault(import('../client/pages/_lang/welfare/index.vue' /* webpackChunkName: "" */))
const _5be7dc38 = () => interopDefault(import('../client/pages/_lang/wxt/index.vue' /* webpackChunkName: "" */))
const _261a00c1 = () => interopDefault(import('../client/pages/_lang/files/white-paper/index.vue' /* webpackChunkName: "" */))
const _a4ac14f0 = () => interopDefault(import('../client/pages/_lang/wxtUnlock/index.vue' /* webpackChunkName: "" */))
const _68a7ac74 = () => interopDefault(import('../client/pages/_lang/about/privacy.vue' /* webpackChunkName: "" */))
const _870e4fbe = () => interopDefault(import('../client/pages/_lang/account/newapi/index.vue' /* webpackChunkName: "" */))
const _c8d8057c = () => interopDefault(import('../client/pages/_lang/account/preference/index.vue' /* webpackChunkName: "" */))
const _6399c442 = () => interopDefault(import('../client/pages/_lang/account/setting/index.vue' /* webpackChunkName: "" */))
const _c8d82422 = () => interopDefault(import('../client/pages/_lang/account/verified/index.vue' /* webpackChunkName: "" */))
const _68c3391b = () => interopDefault(import('../client/pages/_lang/account/welfare/index.vue' /* webpackChunkName: "" */))
const _5b69ed04 = () => interopDefault(import('../client/pages/_lang/activity/competition/index.vue' /* webpackChunkName: "" */))
const _db636a60 = () => interopDefault(import('../client/pages/_lang/activity/newUser/index.vue' /* webpackChunkName: "" */))
const _d5ca30da = () => interopDefault(import('../client/pages/_lang/affiliate/activity.vue' /* webpackChunkName: "" */))
const _56adfaac = () => interopDefault(import('../client/pages/_lang/benefits/invite.vue' /* webpackChunkName: "" */))
const _e7a7dc12 = () => interopDefault(import('../client/pages/_lang/bftmanagement/deliver.vue' /* webpackChunkName: "" */))
const _436c4894 = () => interopDefault(import('../client/pages/_lang/bftmanagement/protocol.vue' /* webpackChunkName: "" */))
const _63a21e6f = () => interopDefault(import('../client/pages/_lang/bftmanagement/record.vue' /* webpackChunkName: "" */))
const _1473a90b = () => interopDefault(import('../client/pages/_lang/buycoin/coin.vue' /* webpackChunkName: "" */))
const _36f0992b = () => interopDefault(import('../client/pages/_lang/challenging/member.vue' /* webpackChunkName: "" */))
const _45c42d60 = () => interopDefault(import('../client/pages/_lang/challenging/v2/index.vue' /* webpackChunkName: "" */))
const _656993f4 = () => interopDefault(import('../client/pages/_lang/community/communitytab.vue' /* webpackChunkName: "" */))
const _b1575002 = () => interopDefault(import('../client/pages/_lang/feedback/form/index.vue' /* webpackChunkName: "" */))
const _3587858f = () => interopDefault(import('../client/pages/_lang/feedback/history/index.vue' /* webpackChunkName: "" */))
const _5ac4c8a9 = () => interopDefault(import('../client/pages/_lang/hire/details.vue' /* webpackChunkName: "" */))
const _1e85f3b9 = () => interopDefault(import('../client/pages/_lang/job/btnForCube/index.vue' /* webpackChunkName: "" */))
const _2868ac04 = () => interopDefault(import('../client/pages/_lang/launchpool/details.vue' /* webpackChunkName: "" */))
const _52392a0c = () => interopDefault(import('../client/pages/_lang/launchpool/NoDataItem.vue' /* webpackChunkName: "" */))
const _2cb8c13a = () => interopDefault(import('../client/pages/_lang/launchpool/RewardHistoryItem.vue' /* webpackChunkName: "" */))
const _4ee84fcf = () => interopDefault(import('../client/pages/_lang/launchpool/ValidPositionsItem.vue' /* webpackChunkName: "" */))
const _e45f1276 = () => interopDefault(import('../client/pages/_lang/layout/test.vue' /* webpackChunkName: "" */))
const _3c3b65c5 = () => interopDefault(import('../client/pages/_lang/login/LoginQrItem.vue' /* webpackChunkName: "" */))
const _53304f45 = () => interopDefault(import('../client/pages/_lang/notice/activityDetail.vue' /* webpackChunkName: "" */))
const _b014f854 = () => interopDefault(import('../client/pages/_lang/notice/detail.vue' /* webpackChunkName: "" */))
const _38b8d8b5 = () => interopDefault(import('../client/pages/_lang/order/contract.vue' /* webpackChunkName: "" */))
const _3cf33574 = () => interopDefault(import('../client/pages/_lang/order/exchange.vue' /* webpackChunkName: "" */))
const _7cbb78ac = () => interopDefault(import('../client/pages/_lang/protectfund/safe.vue' /* webpackChunkName: "" */))
const _5ff4b349 = () => interopDefault(import('../client/pages/_lang/register/index_new.vue' /* webpackChunkName: "" */))
const _41777f76 = () => interopDefault(import('../client/pages/_lang/rewards/act_new.vue' /* webpackChunkName: "" */))
const _dbf6ea9a = () => interopDefault(import('../client/pages/_lang/rewards/bft.vue' /* webpackChunkName: "" */))
const _796f3a3e = () => interopDefault(import('../client/pages/_lang/rewards/bftinfo.vue' /* webpackChunkName: "" */))
const _0c104584 = () => interopDefault(import('../client/pages/_lang/rewards/wpt.vue' /* webpackChunkName: "" */))
const _5e9decfb = () => interopDefault(import('../client/pages/_lang/school/customTabs/index.vue' /* webpackChunkName: "" */))
const _f947cffc = () => interopDefault(import('../client/pages/_lang/support/activityDetail.vue' /* webpackChunkName: "" */))
const _d1237be8 = () => interopDefault(import('../client/pages/_lang/support/contractProtocol.vue' /* webpackChunkName: "" */))
const _5c7977fa = () => interopDefault(import('../client/pages/_lang/support/installationTutorial.vue' /* webpackChunkName: "" */))
const _6249374f = () => interopDefault(import('../client/pages/_lang/support/iosLoginSupportInfo.vue' /* webpackChunkName: "" */))
const _d9e61810 = () => interopDefault(import('../client/pages/_lang/support/jumpdl.vue' /* webpackChunkName: "" */))
const _74a27122 = () => interopDefault(import('../client/pages/_lang/support/rate.vue' /* webpackChunkName: "" */))
const _16113bf9 = () => interopDefault(import('../client/pages/_lang/support/registrationTutorial.vue' /* webpackChunkName: "" */))
const _05578e8f = () => interopDefault(import('../client/pages/_lang/support/specialDownload.vue' /* webpackChunkName: "" */))
const _b307b4cc = () => interopDefault(import('../client/pages/_lang/support/specialDownload-v7k6.vue' /* webpackChunkName: "" */))
const _031bf3e6 = () => interopDefault(import('../client/pages/_lang/topic/agent-introduction-topic.vue' /* webpackChunkName: "" */))
const _6b38e9e4 = () => interopDefault(import('../client/pages/_lang/useragent/h5.vue' /* webpackChunkName: "" */))
const _6a067acd = () => interopDefault(import('../client/pages/_lang/useragent/web.vue' /* webpackChunkName: "" */))
const _7d4bf3e6 = () => interopDefault(import('../client/pages/_lang/welfare/start.vue' /* webpackChunkName: "" */))
const _4d584ffc = () => interopDefault(import('../client/pages/_lang/wxt/presale.vue' /* webpackChunkName: "" */))
const _ee6d0bd0 = () => interopDefault(import('../client/pages/_lang/wxtUnlock/NoDataItem.vue' /* webpackChunkName: "" */))
const _72ea32fe = () => interopDefault(import('../client/pages/_lang/account/setting/antiphishing.vue' /* webpackChunkName: "" */))
const _76ad598c = () => interopDefault(import('../client/pages/_lang/account/setting/bindemail.vue' /* webpackChunkName: "" */))
const _90f8240e = () => interopDefault(import('../client/pages/_lang/account/setting/bindgoogle.vue' /* webpackChunkName: "" */))
const _d6cc783c = () => interopDefault(import('../client/pages/_lang/account/setting/bindmobile.vue' /* webpackChunkName: "" */))
const _bc4ec9ac = () => interopDefault(import('../client/pages/_lang/account/setting/devices.vue' /* webpackChunkName: "" */))
const _4900f358 = () => interopDefault(import('../client/pages/_lang/account/setting/forgetfundpwd.vue' /* webpackChunkName: "" */))
const _fb001c3a = () => interopDefault(import('../client/pages/_lang/account/setting/fundpassword.vue' /* webpackChunkName: "" */))
const _11bf1f61 = () => interopDefault(import('../client/pages/_lang/account/setting/history.vue' /* webpackChunkName: "" */))
const _e218cc40 = () => interopDefault(import('../client/pages/_lang/account/setting/mixinVerifyDialog.js' /* webpackChunkName: "" */))
const _5bd0cb76 = () => interopDefault(import('../client/pages/_lang/account/setting/modifygoogle.vue' /* webpackChunkName: "" */))
const _38e6a15f = () => interopDefault(import('../client/pages/_lang/account/setting/modifymobile.vue' /* webpackChunkName: "" */))
const _1c8ad40a = () => interopDefault(import('../client/pages/_lang/account/setting/pwd.vue' /* webpackChunkName: "" */))
const _46cfd1cb = () => interopDefault(import('../client/pages/_lang/account/verified/overseas.vue' /* webpackChunkName: "" */))
const _96096f32 = () => interopDefault(import('../client/pages/_lang/account/welfare/exclusive.vue' /* webpackChunkName: "" */))
const _062d449a = () => interopDefault(import('../client/pages/_lang/account/welfare/mine.vue' /* webpackChunkName: "" */))
const _0cb5be14 = () => interopDefault(import('../client/pages/_lang/bftmanagement/components/DialogStaking.vue' /* webpackChunkName: "" */))
const _2ae0ed78 = () => interopDefault(import('../client/pages/_lang/bftmanagement/components/DialogTip.vue' /* webpackChunkName: "" */))
const _5c923df7 = () => interopDefault(import('../client/pages/_lang/bftmanagement/components/DialogUnlock.vue' /* webpackChunkName: "" */))
const _45244c13 = () => interopDefault(import('../client/pages/_lang/support/components/allPageLoading.vue' /* webpackChunkName: "" */))
const _206a1460 = () => interopDefault(import('../client/pages/_lang/support/components/message.vue' /* webpackChunkName: "" */))
const _9aa20938 = () => interopDefault(import('../client/pages/_lang/support/utils/common.js' /* webpackChunkName: "" */))
const _e1acd2ea = () => interopDefault(import('../client/pages/_lang/support/utils/deviceInfo.js' /* webpackChunkName: "" */))
const _e9f78ebe = () => interopDefault(import('../client/pages/_lang/support/utils/fingerprint2.js' /* webpackChunkName: "" */))
const _3b3b63bc = () => interopDefault(import('../client/pages/_lang/events/activities/_id.vue' /* webpackChunkName: "" */))
const _6337cf3f = () => interopDefault(import('../client/pages/_lang/how-to-buy/_coinname.vue' /* webpackChunkName: "" */))
const _5dc0ec0b = () => interopDefault(import('../client/pages/_lang/tokens/_infoCoin.vue' /* webpackChunkName: "" */))
const _76af9e40 = () => interopDefault(import('../client/pages/_lang/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _949573c8,
    name: "aboutusEn"
  }, {
    path: "/account",
    component: _6357a2de,
    name: "accountEn"
  }, {
    path: "/affiliate",
    component: _05d4187e,
    name: "affiliateEn"
  }, {
    path: "/becomepartner",
    component: _0157075c,
    name: "becomepartnerEn"
  }, {
    path: "/benefits",
    component: _7850007e,
    name: "benefitsEn"
  }, {
    path: "/bftmanagement",
    component: _9edb0cb8,
    name: "bftmanagementEn"
  }, {
    path: "/buycoin",
    component: _77d23648,
    name: "buycoinEn"
  }, {
    path: "/challenging",
    component: _78b9335e,
    name: "challengingEn"
  }, {
    path: "/community",
    component: _258f9fcc,
    name: "communityEn"
  }, {
    path: "/community",
    component: _258f9fcc,
    name: "-community"
  }, {
    path: "/competition",
    component: _b9affe20,
    name: "competitionEn"
  }, {
    path: "/compliance",
    component: _d6675840,
    name: "complianceEn"
  }, {
    path: "/contactUs",
    component: _5eff5b4c,
    name: "contactUsEn"
  }, {
    path: "/documentary",
    component: _d46205c4,
    name: "documentaryEn"
  }, {
    path: "/download",
    component: _21251a8d,
    name: "downloadEn"
  }, {
    path: "/expressly",
    component: _aa978fa4,
    name: "expresslyEn"
  }, {
    path: "/faq",
    component: _e896a0f2,
    name: "faqEn"
  }, {
    path: "/feedback",
    component: _68a2654a,
    name: "feedbackEn"
  }, {
    path: "/financial",
    component: _2d4b94ba,
    name: "financialEn"
  }, {
    path: "/forget",
    component: _6d34a79c,
    name: "forgetEn"
  }, {
    path: "/global-community",
    component: _1c4714f8,
    name: "global-communityEn"
  }, {
    path: "/global-strategy",
    component: _6c29f10e,
    name: "global-strategyEn"
  }, {
    path: "/globalpartners",
    component: _59622853,
    name: "globalpartnersEn"
  }, {
    path: "/h5",
    name: "-h5"
  }, {
    path: "/hire",
    component: _12161ef9,
    name: "hireEn"
  }, {
    path: "/how-to-buy",
    component: _1dddfed4,
    name: "how-to-buyEn"
  }, {
    path: "/individual",
    component: _14e5c0de,
    name: "individualEn"
  }, {
    path: "/job",
    component: _0fdd4924,
    name: "jobEn"
  }, {
    path: "/launchpool",
    component: _4e811d14,
    name: "launchpoolEn"
  }, {
    path: "/layout",
    component: _45141d2f,
    name: "layoutEn"
  }, {
    path: "/login",
    component: _33a5aacc,
    name: "loginEn"
  }, {
    path: "/lunc-burn",
    component: _216a1f2f,
    name: "lunc-burnEn"
  }, {
    path: "/Media-kit",
    component: _7818a47e,
    name: "Media-kitEn"
  }, {
    path: "/modifyverification",
    component: _5b2bc11a,
    name: "modifyverificationEn"
  }, {
    path: "/news-report",
    component: _4c17d1df,
    name: "news-reportEn"
  }, {
    path: "/notice",
    component: _a4271146,
    name: "noticeEn"
  }, {
    path: "/official-verification",
    component: _e9eaa824,
    name: "official-verificationEn"
  }, {
    path: "/pagestatus",
    component: _53773786,
    name: "pagestatusEn"
  }, {
    path: "/promotion",
    component: _1caa4758,
    name: "promotionEn"
  }, {
    path: "/proof-of-reserves",
    component: _130123d5,
    name: "proof-of-reservesEn"
  }, {
    path: "/protectfund",
    component: _eee279f6,
    name: "protectfundEn"
  }, {
    path: "/publish",
    component: _537f0e7d,
    name: "publishEn"
  }, {
    path: "/quotelist",
    component: _42aa480b,
    name: "quotelistEn"
  }, {
    path: "/rate",
    component: _ffe9d436,
    name: "rateEn"
  }, {
    path: "/register",
    component: _3f2deac8,
    name: "-register"
  }, {
    path: "/school",
    component: _4ebe28f9,
    name: "schoolEn"
  }, {
    path: "/sitemap",
    component: _51ec3b66,
    name: "sitemapEn"
  }, {
    path: "/socialCreateAccount",
    component: _59517e15,
    name: "socialCreateAccountEn"
  }, {
    path: "/spokesperson",
    component: _6e780981,
    name: "spokespersonEn"
  }, {
    path: "/stop",
    component: _54fdce87,
    name: "stopEn"
  }, {
    path: "/support",
    component: _357d1400,
    name: "supportEn"
  }, {
    path: "/tokens",
    component: _74b948c2,
    name: "tokensEn"
  }, {
    path: "/topTraderCompetition",
    component: _63e122fe,
    name: "topTraderCompetitionEn"
  }, {
    path: "/useragent",
    component: _5522b50b,
    name: "useragentEn"
  }, {
    path: "/welfare",
    component: _1022b27d,
    name: "welfareEn"
  }, {
    path: "/wxt",
    component: _5be7dc38,
    name: "wxtEn"
  }, {
    path: "/WXT-whitepaper",
    component: _261a00c1,
    name: "-WXT-whitepaper"
  }, {
    path: "/wxtUnlock",
    component: _a4ac14f0,
    name: "wxtUnlockEn"
  }, {
    path: "/about/privacy",
    component: _68a7ac74,
    name: "about-privacyEn"
  }, {
    path: "/account/newapi",
    component: _870e4fbe,
    name: "account-newapiEn"
  }, {
    path: "/account/preference",
    component: _c8d8057c,
    name: "account-preferenceEn"
  }, {
    path: "/account/setting",
    component: _6399c442,
    name: "account-settingEn"
  }, {
    path: "/account/verified",
    component: _c8d82422,
    name: "account-verifiedEn"
  }, {
    path: "/account/welfare",
    component: _68c3391b,
    name: "account-welfareEn"
  }, {
    path: "/activity/competition",
    component: _5b69ed04,
    name: "activity-competitionEn"
  }, {
    path: "/activity/newUser",
    component: _db636a60,
    name: "activity-newUserEn"
  }, {
    path: "/affiliate/activity",
    component: _d5ca30da,
    name: "affiliate-activityEn"
  }, {
    path: "/benefits/invite",
    component: _56adfaac,
    name: "benefits-inviteEn"
  }, {
    path: "/bftmanagement/deliver",
    component: _e7a7dc12,
    name: "bftmanagement-deliverEn"
  }, {
    path: "/bftmanagement/protocol",
    component: _436c4894,
    name: "bftmanagement-protocolEn"
  }, {
    path: "/bftmanagement/record",
    component: _63a21e6f,
    name: "bftmanagement-recordEn"
  }, {
    path: "/buycoin/coin",
    component: _1473a90b,
    name: "buycoin-coinEn"
  }, {
    path: "/challenging/member",
    component: _36f0992b,
    name: "challenging-memberEn"
  }, {
    path: "/challenging/v2",
    component: _45c42d60,
    name: "challenging-v2En"
  }, {
    path: "/community/communitytab",
    component: _656993f4,
    name: "community-communitytabEn"
  }, {
    path: "/company/aboutus",
    name: "company-aboutusEn"
  }, {
    path: "/feedback/form",
    component: _b1575002,
    name: "feedback-formEn"
  }, {
    path: "/feedback/history",
    component: _3587858f,
    name: "feedback-historyEn"
  }, {
    path: "/files/white-paper",
    component: _261a00c1,
    name: "files-white-paperEn"
  }, {
    path: "/h5/spokesperson",
    name: "-h5-spokesperson"
  }, {
    path: "/hire/details",
    component: _5ac4c8a9,
    name: "hire-detailsEn"
  }, {
    path: "/job/btnForCube",
    component: _1e85f3b9,
    name: "job-btnForCubeEn"
  }, {
    path: "/launchpool/details",
    component: _2868ac04,
    name: "launchpool-detailsEn"
  }, {
    path: "/launchpool/NoDataItem",
    component: _52392a0c,
    name: "launchpool-NoDataItemEn"
  }, {
    path: "/launchpool/RewardHistoryItem",
    component: _2cb8c13a,
    name: "launchpool-RewardHistoryItemEn"
  }, {
    path: "/launchpool/ValidPositionsItem",
    component: _4ee84fcf,
    name: "launchpool-ValidPositionsItemEn"
  }, {
    path: "/layout/test",
    component: _e45f1276,
    name: "layout-testEn"
  }, {
    path: "/login/LoginQrItem",
    component: _3c3b65c5,
    name: "login-LoginQrItemEn"
  }, {
    path: "/notice/activityDetail",
    component: _53304f45,
    name: "notice-activityDetailEn"
  }, {
    path: "/notice/detail",
    component: _b014f854,
    name: "notice-detailEn"
  }, {
    path: "/order/contract",
    component: _38b8d8b5,
    name: "order-contractEn"
  }, {
    path: "/order/exchange",
    component: _3cf33574,
    name: "order-exchangeEn"
  }, {
    path: "/protectfund/safe",
    component: _7cbb78ac,
    name: "protectfund-safeEn"
  }, {
    path: "/register/index_new",
    component: _5ff4b349,
    name: "register-index_newEn"
  }, {
    path: "/rewards/act_new",
    component: _41777f76,
    name: "rewards-act_newEn"
  }, {
    path: "/rewards/bft",
    component: _dbf6ea9a,
    name: "rewards-bftEn"
  }, {
    path: "/rewards/bftinfo",
    component: _796f3a3e,
    name: "rewards-bftinfoEn"
  }, {
    path: "/rewards/wpt",
    component: _0c104584,
    name: "rewards-wptEn"
  }, {
    path: "/school/customTabs",
    component: _5e9decfb,
    name: "school-customTabsEn"
  }, {
    path: "/support/activityDetail",
    component: _f947cffc,
    name: "support-activityDetailEn"
  }, {
    path: "/support/contractProtocol",
    component: _d1237be8,
    name: "support-contractProtocolEn"
  }, {
    path: "/support/installationTutorial",
    component: _5c7977fa,
    name: "support-installationTutorialEn"
  }, {
    path: "/support/iosLoginSupportInfo",
    component: _6249374f,
    name: "support-iosLoginSupportInfoEn"
  }, {
    path: "/support/jumpdl",
    component: _d9e61810,
    name: "support-jumpdlEn"
  }, {
    path: "/support/rate",
    component: _74a27122,
    name: "support-rateEn"
  }, {
    path: "/support/registrationTutorial",
    component: _16113bf9,
    name: "support-registrationTutorialEn"
  }, {
    path: "/support/specialDownload",
    component: _05578e8f,
    name: "support-specialDownloadEn"
  }, {
    path: "/support/specialDownload-v7k6",
    component: _b307b4cc,
    name: "support-specialDownload-v7k6En"
  }, {
    path: "/topic/agent-introduction-topic",
    component: _031bf3e6,
    name: "topic-agent-introduction-topicEn"
  }, {
    path: "/useragent/h5",
    component: _6b38e9e4,
    name: "useragent-h5En"
  }, {
    path: "/useragent/web",
    component: _6a067acd,
    name: "useragent-webEn"
  }, {
    path: "/welfare/register",
    component: _3f2deac8,
    name: "welfare-registerEn"
  }, {
    path: "/welfare/start",
    component: _7d4bf3e6,
    name: "welfare-startEn"
  }, {
    path: "/wxt/presale",
    component: _4d584ffc,
    name: "wxt-presaleEn"
  }, {
    path: "/wxtUnlock/NoDataItem",
    component: _ee6d0bd0,
    name: "wxtUnlock-NoDataItemEn"
  }, {
    path: "/account/setting/antiphishing",
    component: _72ea32fe,
    name: "account-setting-antiphishingEn"
  }, {
    path: "/account/setting/bindemail",
    component: _76ad598c,
    name: "account-setting-bindemailEn"
  }, {
    path: "/account/setting/bindgoogle",
    component: _90f8240e,
    name: "account-setting-bindgoogleEn"
  }, {
    path: "/account/setting/bindmobile",
    component: _d6cc783c,
    name: "account-setting-bindmobileEn"
  }, {
    path: "/account/setting/devices",
    component: _bc4ec9ac,
    name: "account-setting-devicesEn"
  }, {
    path: "/account/setting/forgetfundpwd",
    component: _4900f358,
    name: "account-setting-forgetfundpwdEn"
  }, {
    path: "/account/setting/fundpassword",
    component: _fb001c3a,
    name: "account-setting-fundpasswordEn"
  }, {
    path: "/account/setting/history",
    component: _11bf1f61,
    name: "account-setting-historyEn"
  }, {
    path: "/account/setting/mixinVerifyDialog",
    component: _e218cc40,
    name: "account-setting-mixinVerifyDialogEn"
  }, {
    path: "/account/setting/modifygoogle",
    component: _5bd0cb76,
    name: "account-setting-modifygoogleEn"
  }, {
    path: "/account/setting/modifymobile",
    component: _38e6a15f,
    name: "account-setting-modifymobileEn"
  }, {
    path: "/account/setting/pwd",
    component: _1c8ad40a,
    name: "account-setting-pwdEn"
  }, {
    path: "/account/verified/overseas",
    component: _46cfd1cb,
    name: "account-verified-overseasEn"
  }, {
    path: "/account/welfare/exclusive",
    component: _96096f32,
    name: "account-welfare-exclusiveEn"
  }, {
    path: "/account/welfare/mine",
    component: _062d449a,
    name: "account-welfare-mineEn"
  }, {
    path: "/bftmanagement/components/DialogStaking",
    component: _0cb5be14,
    name: "bftmanagement-components-DialogStakingEn"
  }, {
    path: "/bftmanagement/components/DialogTip",
    component: _2ae0ed78,
    name: "bftmanagement-components-DialogTipEn"
  }, {
    path: "/bftmanagement/components/DialogUnlock",
    component: _5c923df7,
    name: "bftmanagement-components-DialogUnlockEn"
  }, {
    path: "/support/components/allPageLoading",
    component: _45244c13,
    name: "support-components-allPageLoadingEn"
  }, {
    path: "/support/components/message",
    component: _206a1460,
    name: "support-components-messageEn"
  }, {
    path: "/support/utils/common",
    component: _9aa20938,
    name: "support-utils-commonEn"
  }, {
    path: "/support/utils/deviceInfo",
    component: _e1acd2ea,
    name: "support-utils-deviceInfoEn"
  }, {
    path: "/support/utils/fingerprint2",
    component: _e9f78ebe,
    name: "support-utils-fingerprint2En"
  }, {
    path: "/events/acitivies/:id",
    component: _3b3b63bc,
    name: "-events-acitivies-:id"
  }, {
    path: "/events/activities/:id?",
    component: _3b3b63bc,
    name: "events-activities-idEn"
  }, {
    path: "/how-to-buy/:coinname?",
    component: _6337cf3f,
    name: "how-to-buy-coinnameEn"
  }, {
    path: "/pre-ico/:id",
    component: _4d584ffc,
    name: "-pre-ico-:id"
  }, {
    path: "/tokens/:infoCoin",
    component: _5dc0ec0b,
    name: "tokens-infoCoinEn"
  }, {
    path: "/",
    component: _76af9e40,
    name: "En"
  }, {
    path: "/:lang",
    component: _76af9e40,
    name: "lang"
  }, {
    path: "/:lang?/aboutus",
    component: _949573c8,
    name: "lang-aboutus"
  }, {
    path: "/:lang?/account",
    component: _6357a2de,
    name: "lang-account"
  }, {
    path: "/:lang?/affiliate",
    component: _05d4187e,
    name: "lang-affiliate"
  }, {
    path: "/:lang?/becomepartner",
    component: _0157075c,
    name: "lang-becomepartner"
  }, {
    path: "/:lang?/benefits",
    component: _7850007e,
    name: "lang-benefits"
  }, {
    path: "/:lang?/bftmanagement",
    component: _9edb0cb8,
    name: "lang-bftmanagement"
  }, {
    path: "/:lang?/buycoin",
    component: _77d23648,
    name: "lang-buycoin"
  }, {
    path: "/:lang?/challenging",
    component: _78b9335e,
    name: "lang-challenging"
  }, {
    path: "/:lang?/community",
    component: _258f9fcc,
    name: "lang-community"
  }, {
    path: "/:lang?/competition",
    component: _b9affe20,
    name: "lang-competition"
  }, {
    path: "/:lang?/compliance",
    component: _d6675840,
    name: "lang-compliance"
  }, {
    path: "/:lang?/contactUs",
    component: _5eff5b4c,
    name: "lang-contactUs"
  }, {
    path: "/:lang?/documentary",
    component: _d46205c4,
    name: "lang-documentary"
  }, {
    path: "/:lang?/download",
    component: _21251a8d,
    name: "lang-download"
  }, {
    path: "/:lang?/expressly",
    component: _aa978fa4,
    name: "lang-expressly"
  }, {
    path: "/:lang?/faq",
    component: _e896a0f2,
    name: "lang-faq"
  }, {
    path: "/:lang?/feedback",
    component: _68a2654a,
    name: "lang-feedback"
  }, {
    path: "/:lang?/financial",
    component: _2d4b94ba,
    name: "lang-financial"
  }, {
    path: "/:lang?/forget",
    component: _6d34a79c,
    name: "lang-forget"
  }, {
    path: "/:lang?/global-community",
    component: _1c4714f8,
    name: "lang-global-community"
  }, {
    path: "/:lang?/global-strategy",
    component: _6c29f10e,
    name: "lang-global-strategy"
  }, {
    path: "/:lang?/globalpartners",
    component: _59622853,
    name: "lang-globalpartners"
  }, {
    path: "/:lang?/hire",
    component: _12161ef9,
    name: "lang-hire"
  }, {
    path: "/:lang?/how-to-buy",
    component: _1dddfed4,
    name: "lang-how-to-buy"
  }, {
    path: "/:lang?/individual",
    component: _14e5c0de,
    name: "lang-individual"
  }, {
    path: "/:lang?/job",
    component: _0fdd4924,
    name: "lang-job"
  }, {
    path: "/:lang?/launchpool",
    component: _4e811d14,
    name: "lang-launchpool"
  }, {
    path: "/:lang?/layout",
    component: _45141d2f,
    name: "lang-layout"
  }, {
    path: "/:lang?/login",
    component: _33a5aacc,
    name: "lang-login"
  }, {
    path: "/:lang?/lunc-burn",
    component: _216a1f2f,
    name: "lang-lunc-burn"
  }, {
    path: "/:lang?/Media-kit",
    component: _7818a47e,
    name: "lang-Media-kit"
  }, {
    path: "/:lang?/modifyverification",
    component: _5b2bc11a,
    name: "lang-modifyverification"
  }, {
    path: "/:lang?/news-report",
    component: _4c17d1df,
    name: "lang-news-report"
  }, {
    path: "/:lang?/notice",
    component: _a4271146,
    name: "lang-notice"
  }, {
    path: "/:lang?/official-verification",
    component: _e9eaa824,
    name: "lang-official-verification"
  }, {
    path: "/:lang?/pagestatus",
    component: _53773786,
    name: "lang-pagestatus"
  }, {
    path: "/:lang?/promotion",
    component: _1caa4758,
    name: "lang-promotion"
  }, {
    path: "/:lang?/proof-of-reserves",
    component: _130123d5,
    name: "lang-proof-of-reserves"
  }, {
    path: "/:lang?/protectfund",
    component: _eee279f6,
    name: "lang-protectfund"
  }, {
    path: "/:lang?/publish",
    component: _537f0e7d,
    name: "lang-publish"
  }, {
    path: "/:lang?/quotelist",
    component: _42aa480b,
    name: "lang-quotelist"
  }, {
    path: "/:lang?/rate",
    component: _ffe9d436,
    name: "lang-rate"
  }, {
    path: "/:lang?/school",
    component: _4ebe28f9,
    name: "lang-school"
  }, {
    path: "/:lang?/sitemap",
    component: _51ec3b66,
    name: "lang-sitemap"
  }, {
    path: "/:lang?/socialCreateAccount",
    component: _59517e15,
    name: "lang-socialCreateAccount"
  }, {
    path: "/:lang?/spokesperson",
    component: _6e780981,
    name: "lang-spokesperson"
  }, {
    path: "/:lang?/stop",
    component: _54fdce87,
    name: "lang-stop"
  }, {
    path: "/:lang?/support",
    component: _357d1400,
    name: "lang-support"
  }, {
    path: "/:lang?/tokens",
    component: _74b948c2,
    name: "lang-tokens"
  }, {
    path: "/:lang?/topTraderCompetition",
    component: _63e122fe,
    name: "lang-topTraderCompetition"
  }, {
    path: "/:lang?/useragent",
    component: _5522b50b,
    name: "lang-useragent"
  }, {
    path: "/:lang?/welfare",
    component: _1022b27d,
    name: "lang-welfare"
  }, {
    path: "/:lang?/wxt",
    component: _5be7dc38,
    name: "lang-wxt"
  }, {
    path: "/:lang?/wxtUnlock",
    component: _a4ac14f0,
    name: "lang-wxtUnlock"
  }, {
    path: "/:lang/community",
    component: _258f9fcc
  }, {
    path: "/:lang/h5",
    redirect: "/:lang/"
  }, {
    path: "/:lang/register",
    component: _3f2deac8
  }, {
    path: "/:lang/WXT-whitepaper",
    component: _261a00c1
  }, {
    path: "/:lang?/about/privacy",
    component: _68a7ac74,
    name: "lang-about-privacy"
  }, {
    path: "/:lang?/account/newapi",
    component: _870e4fbe,
    name: "lang-account-newapi"
  }, {
    path: "/:lang?/account/preference",
    component: _c8d8057c,
    name: "lang-account-preference"
  }, {
    path: "/:lang?/account/setting",
    component: _6399c442,
    name: "lang-account-setting"
  }, {
    path: "/:lang?/account/verified",
    component: _c8d82422,
    name: "lang-account-verified"
  }, {
    path: "/:lang?/account/welfare",
    component: _68c3391b,
    name: "lang-account-welfare"
  }, {
    path: "/:lang?/activity/competition",
    component: _5b69ed04,
    name: "lang-activity-competition"
  }, {
    path: "/:lang?/activity/newUser",
    component: _db636a60,
    name: "lang-activity-newUser"
  }, {
    path: "/:lang?/affiliate/activity",
    component: _d5ca30da,
    name: "lang-affiliate-activity"
  }, {
    path: "/:lang?/benefits/invite",
    component: _56adfaac,
    name: "lang-benefits-invite"
  }, {
    path: "/:lang?/bftmanagement/deliver",
    component: _e7a7dc12,
    name: "lang-bftmanagement-deliver"
  }, {
    path: "/:lang?/bftmanagement/protocol",
    component: _436c4894,
    name: "lang-bftmanagement-protocol"
  }, {
    path: "/:lang?/bftmanagement/record",
    component: _63a21e6f,
    name: "lang-bftmanagement-record"
  }, {
    path: "/:lang?/buycoin/coin",
    component: _1473a90b,
    name: "lang-buycoin-coin"
  }, {
    path: "/:lang?/challenging/member",
    component: _36f0992b,
    name: "lang-challenging-member"
  }, {
    path: "/:lang?/challenging/v2",
    component: _45c42d60,
    name: "lang-challenging-v2"
  }, {
    path: "/:lang?/community/communitytab",
    component: _656993f4,
    name: "lang-community-communitytab"
  }, {
    path: "/:lang?/feedback/form",
    component: _b1575002,
    name: "lang-feedback-form"
  }, {
    path: "/:lang?/feedback/history",
    component: _3587858f,
    name: "lang-feedback-history"
  }, {
    path: "/:lang?/files/white-paper",
    component: _261a00c1,
    name: "lang-files-white-paper"
  }, {
    path: "/:lang?/hire/details",
    component: _5ac4c8a9,
    name: "lang-hire-details"
  }, {
    path: "/:lang?/job/btnForCube",
    component: _1e85f3b9,
    name: "lang-job-btnForCube"
  }, {
    path: "/:lang?/launchpool/details",
    component: _2868ac04,
    name: "lang-launchpool-details"
  }, {
    path: "/:lang?/launchpool/NoDataItem",
    component: _52392a0c,
    name: "lang-launchpool-NoDataItem"
  }, {
    path: "/:lang?/launchpool/RewardHistoryItem",
    component: _2cb8c13a,
    name: "lang-launchpool-RewardHistoryItem"
  }, {
    path: "/:lang?/launchpool/ValidPositionsItem",
    component: _4ee84fcf,
    name: "lang-launchpool-ValidPositionsItem"
  }, {
    path: "/:lang?/layout/test",
    component: _e45f1276,
    name: "lang-layout-test"
  }, {
    path: "/:lang?/login/LoginQrItem",
    component: _3c3b65c5,
    name: "lang-login-LoginQrItem"
  }, {
    path: "/:lang?/notice/activityDetail",
    component: _53304f45,
    name: "lang-notice-activityDetail"
  }, {
    path: "/:lang?/notice/detail",
    component: _b014f854,
    name: "lang-notice-detail"
  }, {
    path: "/:lang?/order/contract",
    component: _38b8d8b5,
    name: "lang-order-contract"
  }, {
    path: "/:lang?/order/exchange",
    component: _3cf33574,
    name: "lang-order-exchange"
  }, {
    path: "/:lang?/protectfund/safe",
    component: _7cbb78ac,
    name: "lang-protectfund-safe"
  }, {
    path: "/:lang?/register/index_new",
    component: _5ff4b349,
    name: "lang-register-index_new"
  }, {
    path: "/:lang?/rewards/act_new",
    component: _41777f76,
    name: "lang-rewards-act_new"
  }, {
    path: "/:lang?/rewards/bft",
    component: _dbf6ea9a,
    name: "lang-rewards-bft"
  }, {
    path: "/:lang?/rewards/bftinfo",
    component: _796f3a3e,
    name: "lang-rewards-bftinfo"
  }, {
    path: "/:lang?/rewards/wpt",
    component: _0c104584,
    name: "lang-rewards-wpt"
  }, {
    path: "/:lang?/school/customTabs",
    component: _5e9decfb,
    name: "lang-school-customTabs"
  }, {
    path: "/:lang?/support/activityDetail",
    component: _f947cffc,
    name: "lang-support-activityDetail"
  }, {
    path: "/:lang?/support/contractProtocol",
    component: _d1237be8,
    name: "lang-support-contractProtocol"
  }, {
    path: "/:lang?/support/installationTutorial",
    component: _5c7977fa,
    name: "lang-support-installationTutorial"
  }, {
    path: "/:lang?/support/iosLoginSupportInfo",
    component: _6249374f,
    name: "lang-support-iosLoginSupportInfo"
  }, {
    path: "/:lang?/support/jumpdl",
    component: _d9e61810,
    name: "lang-support-jumpdl"
  }, {
    path: "/:lang?/support/rate",
    component: _74a27122,
    name: "lang-support-rate"
  }, {
    path: "/:lang?/support/registrationTutorial",
    component: _16113bf9,
    name: "lang-support-registrationTutorial"
  }, {
    path: "/:lang?/support/specialDownload",
    component: _05578e8f,
    name: "lang-support-specialDownload"
  }, {
    path: "/:lang?/support/specialDownload-v7k6",
    component: _b307b4cc,
    name: "lang-support-specialDownload-v7k6"
  }, {
    path: "/:lang?/topic/agent-introduction-topic",
    component: _031bf3e6,
    name: "lang-topic-agent-introduction-topic"
  }, {
    path: "/:lang?/useragent/h5",
    component: _6b38e9e4,
    name: "lang-useragent-h5"
  }, {
    path: "/:lang?/useragent/web",
    component: _6a067acd,
    name: "lang-useragent-web"
  }, {
    path: "/:lang?/welfare/register",
    component: _3f2deac8,
    name: "lang-welfare-register"
  }, {
    path: "/:lang?/welfare/start",
    component: _7d4bf3e6,
    name: "lang-welfare-start"
  }, {
    path: "/:lang?/wxt/presale",
    component: _4d584ffc,
    name: "lang-wxt-presale"
  }, {
    path: "/:lang?/wxtUnlock/NoDataItem",
    component: _ee6d0bd0,
    name: "lang-wxtUnlock-NoDataItem"
  }, {
    path: "/:lang/company/aboutus",
    redirect: "/:lang/aboutus",
    name: "company-aboutus"
  }, {
    path: "/:lang/h5/spokesperson",
    redirect: "/:lang/spokesperson"
  }, {
    path: "/:lang?/account/setting/antiphishing",
    component: _72ea32fe,
    name: "lang-account-setting-antiphishing"
  }, {
    path: "/:lang?/account/setting/bindemail",
    component: _76ad598c,
    name: "lang-account-setting-bindemail"
  }, {
    path: "/:lang?/account/setting/bindgoogle",
    component: _90f8240e,
    name: "lang-account-setting-bindgoogle"
  }, {
    path: "/:lang?/account/setting/bindmobile",
    component: _d6cc783c,
    name: "lang-account-setting-bindmobile"
  }, {
    path: "/:lang?/account/setting/devices",
    component: _bc4ec9ac,
    name: "lang-account-setting-devices"
  }, {
    path: "/:lang?/account/setting/forgetfundpwd",
    component: _4900f358,
    name: "lang-account-setting-forgetfundpwd"
  }, {
    path: "/:lang?/account/setting/fundpassword",
    component: _fb001c3a,
    name: "lang-account-setting-fundpassword"
  }, {
    path: "/:lang?/account/setting/history",
    component: _11bf1f61,
    name: "lang-account-setting-history"
  }, {
    path: "/:lang?/account/setting/mixinVerifyDialog",
    component: _e218cc40,
    name: "lang-account-setting-mixinVerifyDialog"
  }, {
    path: "/:lang?/account/setting/modifygoogle",
    component: _5bd0cb76,
    name: "lang-account-setting-modifygoogle"
  }, {
    path: "/:lang?/account/setting/modifymobile",
    component: _38e6a15f,
    name: "lang-account-setting-modifymobile"
  }, {
    path: "/:lang?/account/setting/pwd",
    component: _1c8ad40a,
    name: "lang-account-setting-pwd"
  }, {
    path: "/:lang?/account/verified/overseas",
    component: _46cfd1cb,
    name: "lang-account-verified-overseas"
  }, {
    path: "/:lang?/account/welfare/exclusive",
    component: _96096f32,
    name: "lang-account-welfare-exclusive"
  }, {
    path: "/:lang?/account/welfare/mine",
    component: _062d449a,
    name: "lang-account-welfare-mine"
  }, {
    path: "/:lang?/bftmanagement/components/DialogStaking",
    component: _0cb5be14,
    name: "lang-bftmanagement-components-DialogStaking"
  }, {
    path: "/:lang?/bftmanagement/components/DialogTip",
    component: _2ae0ed78,
    name: "lang-bftmanagement-components-DialogTip"
  }, {
    path: "/:lang?/bftmanagement/components/DialogUnlock",
    component: _5c923df7,
    name: "lang-bftmanagement-components-DialogUnlock"
  }, {
    path: "/:lang?/support/components/allPageLoading",
    component: _45244c13,
    name: "lang-support-components-allPageLoading"
  }, {
    path: "/:lang?/support/components/message",
    component: _206a1460,
    name: "lang-support-components-message"
  }, {
    path: "/:lang?/support/utils/common",
    component: _9aa20938,
    name: "lang-support-utils-common"
  }, {
    path: "/:lang?/support/utils/deviceInfo",
    component: _e1acd2ea,
    name: "lang-support-utils-deviceInfo"
  }, {
    path: "/:lang?/support/utils/fingerprint2",
    component: _e9f78ebe,
    name: "lang-support-utils-fingerprint2"
  }, {
    path: "/:lang?/events/activities/:id?",
    component: _3b3b63bc,
    name: "lang-events-activities-id"
  }, {
    path: "/:lang/events/acitivies/:id",
    component: _3b3b63bc
  }, {
    path: "/:lang?/how-to-buy/:coinname?",
    component: _6337cf3f,
    name: "lang-how-to-buy-coinname"
  }, {
    path: "/:lang?/tokens/:infoCoin",
    component: _5dc0ec0b,
    name: "lang-tokens-infoCoin"
  }, {
    path: "/:lang/pre-ico/:id",
    component: _4d584ffc
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
