import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../client/layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_workbox_6cf6843c from 'nuxt_plugin_workbox_6cf6843c' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_313b02f5 from 'nuxt_plugin_metaplugin_313b02f5' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_aescbcdecrypt_ba2413e8 from 'nuxt_plugin_aescbcdecrypt_ba2413e8' // Source: ../client/plugins/aes_cbc_decrypt.js (mode: 'client')
import nuxt_plugin_authToken_ac9f6f06 from 'nuxt_plugin_authToken_ac9f6f06' // Source: ../client/plugins/authToken.js (mode: 'client')
import nuxt_plugin_detectSpeed_a0e248ac from 'nuxt_plugin_detectSpeed_a0e248ac' // Source: ../client/plugins/detectSpeed.js (mode: 'client')
import nuxt_plugin_elementui_0a43734a from 'nuxt_plugin_elementui_0a43734a' // Source: ../client/plugins/element-ui.js (mode: 'client')
import nuxt_plugin_filters_2b4f519a from 'nuxt_plugin_filters_2b4f519a' // Source: ../client/plugins/filters.js (mode: 'client')
import nuxt_plugin_firebase_087e9259 from 'nuxt_plugin_firebase_087e9259' // Source: ../client/plugins/firebase.js (mode: 'client')
import nuxt_plugin_ga_fb0a2534 from 'nuxt_plugin_ga_fb0a2534' // Source: ../client/plugins/ga.js (mode: 'client')
import nuxt_plugin_globalComponents_0221c707 from 'nuxt_plugin_globalComponents_0221c707' // Source: ../client/plugins/globalComponents.js (mode: 'client')
import nuxt_plugin_globalUtils_1578d800 from 'nuxt_plugin_globalUtils_1578d800' // Source: ../client/plugins/globalUtils.js (mode: 'client')
import nuxt_plugin_gt4_641f5372 from 'nuxt_plugin_gt4_641f5372' // Source: ../client/plugins/gt4.js (mode: 'client')
import nuxt_plugin_HandmadeMessage_f0dc7c2a from 'nuxt_plugin_HandmadeMessage_f0dc7c2a' // Source: ../client/plugins/HandmadeMessage.js (mode: 'client')
import nuxt_plugin_highcharts_047b1fa9 from 'nuxt_plugin_highcharts_047b1fa9' // Source: ../client/plugins/highcharts.js (mode: 'client')
import nuxt_plugin_i18n_3032693c from 'nuxt_plugin_i18n_3032693c' // Source: ../client/plugins/i18n.js (mode: 'all')
import nuxt_plugin_sensors_a6242316 from 'nuxt_plugin_sensors_a6242316' // Source: ../client/plugins/sensors.js (mode: 'client')
import nuxt_plugin_errorHandler_645039de from 'nuxt_plugin_errorHandler_645039de' // Source: ../client/plugins/errorHandler.js (mode: 'client')
import nuxt_plugin_slimscroll_05027cf6 from 'nuxt_plugin_slimscroll_05027cf6' // Source: ../client/plugins/slimscroll.js (mode: 'client')
import nuxt_plugin_swiperssr_13c179ba from 'nuxt_plugin_swiperssr_13c179ba' // Source: ../client/plugins/swiper-ssr.js (mode: 'client')
import nuxt_plugin_vueinject_b9c33670 from 'nuxt_plugin_vueinject_b9c33670' // Source: ../client/plugins/vue-inject.js (mode: 'client')
import nuxt_plugin_vuelazyload_7b31a7d0 from 'nuxt_plugin_vuelazyload_7b31a7d0' // Source: ../client/plugins/vue-lazyload (mode: 'client')
import nuxt_plugin_watchRouter_2246e996 from 'nuxt_plugin_watchRouter_2246e996' // Source: ../client/plugins/watchRouter.js (mode: 'client')
import nuxt_plugin_websocketclient_4a87e022 from 'nuxt_plugin_websocketclient_4a87e022' // Source: ../client/plugins/web-socket-client.js (mode: 'client')
import nuxt_plugin_zendesk_306c8bd2 from 'nuxt_plugin_zendesk_306c8bd2' // Source: ../client/plugins/zendesk.js (mode: 'client')
import nuxt_plugin_vueslickcarousel_7805efe4 from 'nuxt_plugin_vueslickcarousel_7805efe4' // Source: ../client/plugins/vue-slick-carousel.js (mode: 'client')
import nuxt_plugin_UIInspection_1111f0ce from 'nuxt_plugin_UIInspection_1111f0ce' // Source: ../client/plugins/UIInspection.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"WEEX - Crypto Futures Trading Exchange for Bitcoin, Ethereum and Altcoins","meta":[{"charset":"utf-8"},{"name":"renderer","content":"webkit"},{"http-equiv":"X-UA-Compatible","content":"IE=edge,chrome=1"},{"name":"format-detection","content":"telephone=no,email=no,address=no"},{"name":"viewport","content":"width=device-width, initial-scale=1, user-scalable=no"},{"hid":"keywords","name":"keywords","content":"WEEX,WEEX exchange,WEEX platform,WEEX trading,WEEX bitcoin price,WEEX official website"},{"hid":"description","name":"description","content":"WEEX is a Singapore-based crypto futures trading platform that focuses on security and ease of use. It is one of the world's top futures exchanges in terms of trading depth and has obtained licenses from the US MSB and Canadian MSB."},{"name":"robots","content":"index, follow"},{"name":"robots","content":"noodp, noydir"},{"hid":"charset","charset":"utf-8"},{"hid":"mobile-web-app-capable","name":"mobile-web-app-capable","content":"yes"},{"hid":"apple-mobile-web-app-title","name":"apple-mobile-web-app-title","content":"WEEX"},{"hid":"author","name":"author","content":"wangzhenpeng"},{"hid":"og:type","name":"og:type","property":"og:type","content":"website"},{"hid":"og:title","name":"og:title","property":"og:title","content":"WEEX"},{"hid":"og:site_name","name":"og:site_name","property":"og:site_name","content":"WEEX"},{"hid":"og:description","name":"og:description","property":"og:description","content":"WEEX is a Singapore-based crypto futures trading platform that focuses on security and ease of use. It is one of the world's top futures exchanges in terms of trading depth and has obtained licenses from the US MSB and Canadian MSB."}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Fbaseasset\u002Ffavicon.ico"},{"rel":"manifest","href":"\u002F_nuxt\u002Fmanifest.53818b8c.json","hid":"manifest"}],"style":[],"script":[],"htmlAttrs":{"lang":"en"}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.client && typeof nuxt_plugin_workbox_6cf6843c === 'function') {
    await nuxt_plugin_workbox_6cf6843c(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_313b02f5 === 'function') {
    await nuxt_plugin_metaplugin_313b02f5(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_aescbcdecrypt_ba2413e8 === 'function') {
    await nuxt_plugin_aescbcdecrypt_ba2413e8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_authToken_ac9f6f06 === 'function') {
    await nuxt_plugin_authToken_ac9f6f06(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_detectSpeed_a0e248ac === 'function') {
    await nuxt_plugin_detectSpeed_a0e248ac(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_elementui_0a43734a === 'function') {
    await nuxt_plugin_elementui_0a43734a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_filters_2b4f519a === 'function') {
    await nuxt_plugin_filters_2b4f519a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_firebase_087e9259 === 'function') {
    await nuxt_plugin_firebase_087e9259(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_ga_fb0a2534 === 'function') {
    await nuxt_plugin_ga_fb0a2534(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_globalComponents_0221c707 === 'function') {
    await nuxt_plugin_globalComponents_0221c707(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_globalUtils_1578d800 === 'function') {
    await nuxt_plugin_globalUtils_1578d800(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gt4_641f5372 === 'function') {
    await nuxt_plugin_gt4_641f5372(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_HandmadeMessage_f0dc7c2a === 'function') {
    await nuxt_plugin_HandmadeMessage_f0dc7c2a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_highcharts_047b1fa9 === 'function') {
    await nuxt_plugin_highcharts_047b1fa9(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_3032693c === 'function') {
    await nuxt_plugin_i18n_3032693c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sensors_a6242316 === 'function') {
    await nuxt_plugin_sensors_a6242316(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_errorHandler_645039de === 'function') {
    await nuxt_plugin_errorHandler_645039de(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_slimscroll_05027cf6 === 'function') {
    await nuxt_plugin_slimscroll_05027cf6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_swiperssr_13c179ba === 'function') {
    await nuxt_plugin_swiperssr_13c179ba(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueinject_b9c33670 === 'function') {
    await nuxt_plugin_vueinject_b9c33670(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuelazyload_7b31a7d0 === 'function') {
    await nuxt_plugin_vuelazyload_7b31a7d0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_watchRouter_2246e996 === 'function') {
    await nuxt_plugin_watchRouter_2246e996(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_websocketclient_4a87e022 === 'function') {
    await nuxt_plugin_websocketclient_4a87e022(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_zendesk_306c8bd2 === 'function') {
    await nuxt_plugin_zendesk_306c8bd2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueslickcarousel_7805efe4 === 'function') {
    await nuxt_plugin_vueslickcarousel_7805efe4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_UIInspection_1111f0ce === 'function') {
    await nuxt_plugin_UIInspection_1111f0ce(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
